
*,
  *::after,
  *::before {
    margin: 0px;
    padding: 0px;
    box-sizing: inherit;
  }

  html {
    font-size: 62.5%;
  }

  body {
    box-sizing: border-box;
    font-family: 'Roboto Slab', serif;
    margin-right: 4rem;
    margin-left: 4rem;
  }

.Title {
    display: flex;
    font-size: 3.5rem;
    font-weight: bold;
    animation: moveInLeft 1s ease-out;
}

.Subtitle {
    display: flex;
    justify-content: center;
    font-size: 2.5rem;
}

.Paragraph {
    font-size: 1.5rem;
    display: flex;
    justify-content: flex-start;
    align-content: flex-start;
    font-family: sans-serif;
    padding: 0.5rem;
    margin: 0.5rem;
}

.btn {
  width: 9rem;
  height: 3rem;
  box-shadow: 0 1rem 2rem rgba(#272727,.2);
  color:#fff;
  background-color:#272727;
  padding: 6px;
  text-align: center;
  text-decoration: none;
  display: flex;
  font-size: 11px;
  border-radius: 10px; 
  justify-content: center;
  align-items: center
}

@media (max-width: 1200px) {
  body {
    margin-right: 0em;
    margin-left: 0em;
  }
}

@media (max-width: 800px) {
  .Title {
    font-size: 3rem;
    margin-right: 0rem;
    margin-left: 0rem;
  }
}

@keyframes homeAnimation{
  0%{
      transform: rotate(0);
      opacity: 0;
      }
  100%{
      transform: rotate(0);
      opacity: 1;
      }
}

@keyframes moveInBottom {
  0% {
      opacity: 0;
      transform: translateY(3rem);
  }
  
  100% {
      opacity: 1;
      transform: translate(0);
  } 
}