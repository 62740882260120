.Social {
    box-shadow: 0 1rem 2rem rgba(black);
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    color: black;
    padding: 3rem;
    justify-content: center;
    align-items: center;
    margin-bottom: 4rem;
    margin-top: 4rem;
    background: rgb(225,201,255);
    background: radial-gradient(circle, rgb(242, 189, 197) 0%, rgb(249, 180, 134) 100%);
    background-size: cover;
}

.cv {
    width: 11.5rem;
    justify-content: center;
    align-items: center
}

@media (max-width: 700px) {
    .Social {
        margin-top: 0rem;
        margin-bottom: 0rem;
        border-radius: 0px;
    }
  }