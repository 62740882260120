.Home {
    box-shadow: 0 1rem 2rem rgba(#272727,.2);
    margin-top: 4rem;
    margin-bottom: 4rem;
    border-radius: 10px;
    height: 100%;
    display: inline-flex;
    color: #272727;
    background: rgb(247, 176, 187);
    background: radial-gradient(circle, rgb(242, 189, 197) 0%, rgb(249, 180, 134) 100%);
    background-size: cover;
    width: 100%;
    align-items: center;
    justify-content: center;
}

.About {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 4rem;
    width: 70%;
}

.Main {
    display: flex;
    flex-direction: inline-flex;
    justify-content: space-evenly;
}

.Intro {
    width: 70%;
}

.Summary {
    font-size: 1.5rem;
    font-family: sans-serif;
}

.Jasmine {
    height: 230px;
    border-radius: 50%;
}

@media (max-width: 700px) {
    .About {
        width: 100%;
        margin: 3rem;
    }
    .Home {
        margin-top: 0rem;
        margin-bottom: 0rem;
        border-radius: 0px;
        padding-top: 2rem;
    }
    .Main {
        display: flex;
        flex-direction: inline-flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
    }
    .Intro {
        width: 100%;
    }
    .Jasmine {
        height: 200px
    }
  }

  @keyframes moveInLeft {
    0% {
        opacity: 0;
        transform: translateX(-100px);
    }
    
    80% {
        transform: translate(10px);

    }

    100% {
        opacity: 1;
        transform: translate(0);
    } 
}