body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


*,
  *::after,
  *::before {
    margin: 0px;
    padding: 0px;
    box-sizing: inherit;
  }

  html {
    font-size: 62.5%;
  }

  body {
    box-sizing: border-box;
    font-family: 'Roboto Slab', serif;
    margin-right: 4rem;
    margin-left: 4rem;
  }

.Title {
    display: flex;
    font-size: 3.5rem;
    font-weight: bold;
    -webkit-animation: moveInLeft 1s ease-out;
            animation: moveInLeft 1s ease-out;
}

.Subtitle {
    display: flex;
    justify-content: center;
    font-size: 2.5rem;
}

.Paragraph {
    font-size: 1.5rem;
    display: flex;
    justify-content: flex-start;
    align-content: flex-start;
    font-family: sans-serif;
    padding: 0.5rem;
    margin: 0.5rem;
}

.btn {
  width: 9rem;
  height: 3rem;
  box-shadow: 0 1rem 2rem rgba(#272727,.2);
  color:#fff;
  background-color:#272727;
  padding: 6px;
  text-align: center;
  text-decoration: none;
  display: flex;
  font-size: 11px;
  border-radius: 10px; 
  justify-content: center;
  align-items: center
}

@media (max-width: 1200px) {
  body {
    margin-right: 0em;
    margin-left: 0em;
  }
}

@media (max-width: 800px) {
  .Title {
    font-size: 3rem;
    margin-right: 0rem;
    margin-left: 0rem;
  }
}

@-webkit-keyframes homeAnimation{
  0%{
      -webkit-transform: rotate(0);
              transform: rotate(0);
      opacity: 0;
      }
  100%{
      -webkit-transform: rotate(0);
              transform: rotate(0);
      opacity: 1;
      }
}

@keyframes homeAnimation{
  0%{
      -webkit-transform: rotate(0);
              transform: rotate(0);
      opacity: 0;
      }
  100%{
      -webkit-transform: rotate(0);
              transform: rotate(0);
      opacity: 1;
      }
}

@-webkit-keyframes moveInBottom {
  0% {
      opacity: 0;
      -webkit-transform: translateY(3rem);
              transform: translateY(3rem);
  }
  
  100% {
      opacity: 1;
      -webkit-transform: translate(0);
              transform: translate(0);
  } 
}

@keyframes moveInBottom {
  0% {
      opacity: 0;
      -webkit-transform: translateY(3rem);
              transform: translateY(3rem);
  }
  
  100% {
      opacity: 1;
      -webkit-transform: translate(0);
              transform: translate(0);
  } 
}
.Home {
    box-shadow: 0 1rem 2rem rgba(#272727,.2);
    margin-top: 4rem;
    margin-bottom: 4rem;
    border-radius: 10px;
    height: 100%;
    display: inline-flex;
    color: #272727;
    background: rgb(247, 176, 187);
    background: radial-gradient(circle, rgb(242, 189, 197) 0%, rgb(249, 180, 134) 100%);
    background-size: cover;
    width: 100%;
    align-items: center;
    justify-content: center;
}

.About {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 4rem;
    width: 70%;
}

.Main {
    display: flex;
    flex-direction: inline-flex;
    justify-content: space-evenly;
}

.Intro {
    width: 70%;
}

.Summary {
    font-size: 1.5rem;
    font-family: sans-serif;
}

.Jasmine {
    height: 230px;
    border-radius: 50%;
}

@media (max-width: 700px) {
    .About {
        width: 100%;
        margin: 3rem;
    }
    .Home {
        margin-top: 0rem;
        margin-bottom: 0rem;
        border-radius: 0px;
        padding-top: 2rem;
    }
    .Main {
        display: flex;
        flex-direction: inline-flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
    }
    .Intro {
        width: 100%;
    }
    .Jasmine {
        height: 200px
    }
  }

  @-webkit-keyframes moveInLeft {
    0% {
        opacity: 0;
        -webkit-transform: translateX(-100px);
                transform: translateX(-100px);
    }
    
    80% {
        -webkit-transform: translate(10px);
                transform: translate(10px);

    }

    100% {
        opacity: 1;
        -webkit-transform: translate(0);
                transform: translate(0);
    } 
}

  @keyframes moveInLeft {
    0% {
        opacity: 0;
        -webkit-transform: translateX(-100px);
                transform: translateX(-100px);
    }
    
    80% {
        -webkit-transform: translate(10px);
                transform: translate(10px);

    }

    100% {
        opacity: 1;
        -webkit-transform: translate(0);
                transform: translate(0);
    } 
}
.Social {
    box-shadow: 0 1rem 2rem rgba(black);
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    color: black;
    padding: 3rem;
    justify-content: center;
    align-items: center;
    margin-bottom: 4rem;
    margin-top: 4rem;
    background: rgb(225,201,255);
    background: radial-gradient(circle, rgb(242, 189, 197) 0%, rgb(249, 180, 134) 100%);
    background-size: cover;
}

.cv {
    width: 11.5rem;
    justify-content: center;
    align-items: center
}

@media (max-width: 700px) {
    .Social {
        margin-top: 0rem;
        margin-bottom: 0rem;
        border-radius: 0px;
    }
  }
.Projects {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: #f5f5f5;
    border-radius: 10px;
}

.project-title {
    margin-top: 4rem;
}

.Projects_container {
    display: inline-flex;
    flex-direction: row;
    justify-content: space-between;
    align-content: center;
    flex-wrap: wrap;
    margin: 2rem;
}

.Projects_box {
    border-radius: 10px;
    width: 350px;
    display: flex;
    align-content: center;
    justify-content: center;
    flex-direction: column;
    position: relative;
    height: 350px;
    margin: 2rem;
}
.Projects_box:hover {
    visibility: visible;
    background: #fff;
    padding: 3rem;
    -webkit-animation: homeAnimation 1s forwards 0s linear;
            animation: homeAnimation 1s forwards 0s linear;
    box-shadow: 0 1rem 2rem rgba(39, 39, 39, .2);
}
.Projects_box:hover .Projects_image {
    opacity: 1%;
    z-index: -1;
}

.buttons {
    display: flex;
    justify-content: space-evenly;
}

.Projects_image {
    left: 0;
    position: absolute;
    height: 350px;
    width: 350px;
    margin: 0;
    box-shadow: 0 1rem 2rem rgba(272727, 2, 0, );
    border-radius: 10px;
}

.Projects_image:active {
    z-index: -1;
}

@media (max-width: 800px) {
    .Projects_container {
        justify-content: center;
        margin: 0rem;
  }
    .Projects_box {
        justify-content: space-around;
        margin-left: 1rem;
        margin-right: 1rem;
  }
  .project-title {
    margin-top: 2rem;
}
}  

@media (max-width: 360px) {
    .Projects_box {
        justify-content: space-around;
        margin-left: 0rem;
        margin-right: 0rem;
  }
}
