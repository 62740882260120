.Projects {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: #f5f5f5;
    border-radius: 10px;
}

.project-title {
    margin-top: 4rem;
}

.Projects_container {
    display: inline-flex;
    flex-direction: row;
    justify-content: space-between;
    align-content: center;
    flex-wrap: wrap;
    margin: 2rem;
}

.Projects_box {
    border-radius: 10px;
    width: 350px;
    display: flex;
    align-content: center;
    justify-content: center;
    flex-direction: column;
    position: relative;
    height: 350px;
    margin: 2rem;
}
.Projects_box:hover {
    visibility: visible;
    background: #fff;
    padding: 3rem;
    animation: homeAnimation 1s forwards 0s linear;
    box-shadow: 0 1rem 2rem rgba(39, 39, 39, .2);
}
.Projects_box:hover .Projects_image {
    opacity: 1%;
    z-index: -1;
}

.buttons {
    display: flex;
    justify-content: space-evenly;
}

.Projects_image {
    left: 0;
    position: absolute;
    height: 350px;
    width: 350px;
    margin: 0;
    box-shadow: 0 1rem 2rem rgba(272727, 2, 0, );
    border-radius: 10px;
}

.Projects_image:active {
    z-index: -1;
}

@media (max-width: 800px) {
    .Projects_container {
        justify-content: center;
        margin: 0rem;
  }
    .Projects_box {
        justify-content: space-around;
        margin-left: 1rem;
        margin-right: 1rem;
  }
  .project-title {
    margin-top: 2rem;
}
}  

@media (max-width: 360px) {
    .Projects_box {
        justify-content: space-around;
        margin-left: 0rem;
        margin-right: 0rem;
  }
}